
html { height: 100%; overflow:auto; }
body { height: 100%; }

h2 {
  margin: 0;
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1.5em 0;
  padding: 0;
}

b { /* used for event dates/times */
  margin-right: 3px;

}

.calendar {
  display: flex;
  min-height: 100%;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  font-size: 14px;
}

.calendar-sidebar {
  width: 300px;
  line-height: 1.5;
  border-right: 1px solid #d3e2e8;
  background: #FFFFFF;
  box-shadow: 0px 4px 14px rgba(114, 114, 122, 0.25);
  border-radius: 4px;
  margin: 40px 10px;
  margin-bottom: 0px;
}

.calendar-sidebar-section {
  padding: 2em;
}

.calendar-main {
  flex-grow: 1;
  padding: 3em;
}

.fc { 
  max-width: 1100px;
  margin: 0 auto;
}
i{
  font-size: 15px;
  text-align: center;
  border-radius: 0px;

}

#events{
margin-top: 90%;
color: black;
}
.fc-h-event{
  border: 0px;
}